<template>
    <div>
        <el-card class="box-card animate__animated animate__fadeIn" v-loading="isLoading"
                 v-if="!itemDetail || itemDetail === null">
            <div slot="header" class="clearfix">
                <div class="d-flex align-items-center w-100">
                    <div class="flex-fill mr-2">
                        <el-input placeholder="Please input"
                                  v-model="itemModel.classifyName"
                                  class="bg-white"
                                  clearable>
                        </el-input>
                    </div>
                    <div>
                        <div v-if="!isEditMode">
                            <el-button type="primary"
                                       :disabled="itemModel.classifyName === null || itemModel.classifyName === ''"
                                       @click="saveChange">
                                <i class="el-icon-plus"></i>
                            </el-button>
                            <el-button type="primary" v-if="itemModel.classifyName === null || itemModel.classifyName === ''"
                                       @click="refreshData">
                                <i class="el-icon-refresh"></i> 
                            </el-button>
                        </div>
                        <div v-else>                            
                            <el-button type="primary"
                                       @click="saveChange">
                                <i class="el-icon-check"></i>
                            </el-button>                        
                            <el-button type="danger" @click="cancelEdit">
                                <i class="el-icon-close"></i>
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <table class="table table-striped table-borderless w-100"
                       v-if="userTagClassifyList.length > 0">
                    <tbody>
                        <tr v-for="item in userTagClassifyList" :key="item.id">
                            <td class="p-2 v-center"><i class="fas fa-tag text-info mr-1"></i> {{item.classifyName}}</td>
                            <td style="width: 1%;" class="v-center p-2 text-nowrap text-right">
                                <el-tooltip content="Rename" placement="top" effect="light"
                                            v-if="item.canEdit">
                                    <el-button size="mini" icon="el-icon-edit" circle
                                               class="bg-gradient-navy text-white"
                                               @click="editItem(item)"></el-button>
                                </el-tooltip>
                                <el-tooltip content="Settings" placement="top" effect="light">
                                    <el-button size="mini" icon="el-icon-setting" circle
                                               class="bg-gradient-navy text-white"
                                               @click="itemDetail = item"></el-button>
                                </el-tooltip>
                                <el-tooltip content="Delete" placement="top" effect="light">
                                    <el-button type="danger" size="mini" icon="el-icon-delete" circle
                                               v-if="item.canDeleted && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))"
                                               @click="deleteItem(item)"></el-button>
                                </el-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="d-flex align-items-center justify-content-center">
                    <div class="text-center">
                        <div>
                            <img src="/img/dribbble_2.gif" style="width: 90%;"/>
                        </div>
                        <div class="text-muted mt-1">
                            <h3 class="mb-0"><strong>NOTHING !</strong></h3>
                            <div><i>Your collection list is empty.</i></div>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>

        <el-card class="box-card animate__animated animate__fadeIn" v-loading="isLoadingPackageInfo"
                 v-if="itemDetail && itemDetail !== null">
            <div slot="header" class="clearfix">
                <div class="d-flex align-items-center w-100">
                    <div class="flex-fill mr-2">
                        <h4 class="mb-0"><strong>{{itemDetail.classifyName}}</strong></h4>
                        <div><span>Choose the package you want to display</span></div>
                    </div>
                    <div>
                        <el-tooltip content="Close" placement="top" effect="light">
                            <el-button size="mini" icon="el-icon-close" circle
                                        class="bg-gradient-danger text-white"
                                        @click="itemDetail = null"></el-button>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div>
                <table class="table table-striped table-borderless w-100"
                       v-if="listServicePackClassify.length > 0">
                    <tbody>
                        <tr v-for="item in listServicePackClassify" :key="item.id">
                            <td class="p-2 v-center">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <i class="el-icon-box fa-2x text-info mr-1"></i> 
                                    </div>
                                    <div>
                                        <div style="line-height: 1rem;"><strong>{{item.PackageName}}</strong></div>
                                        <div style="line-height: 1rem;">
                                            <small class="text-muted">{{item.PackageDescription}}</small> - 
                                            <small class="text-muted">${{item.PackagePricing}}/hour</small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 1%;" class="v-center p-2 text-nowrap">
                                <el-switch v-model="item.isEnabledBool" 
                                           @change="onPakageEnabledChanged(item)"/>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="d-flex align-items-center justify-content-center">
                    <div class="text-center">
                        <div>
                            <img src="/img/dribbble_2.gif" style="width: 90%;"/>
                        </div>
                        <div class="text-muted mt-1">
                            <h3 class="mb-0"><strong>NOTHING !</strong></h3>
                            <div><i>No package.</i></div>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import userTagClassifyApi from '@/api/common/userTagClassify';
    export default {
        data() {
            return {
                isLoading: false,
                isEditMode: false,
                itemModel: { classifyName: '' },
                itemDetail: null,
                
                isLoadingPackageInfo: false,
                listServicePackClassify: []
            };
        },
        watch: {
            itemDetail() {
                this.packageInfo();
            }
        },
        computed: {
            ...mapState({
                userInfo: state => state.auth.user,
                rolesInfo: state => state.auth.roles,
                userTagClassifyList: state => state.common.userClassifyAll.map(x => ({
                    ...x, canEdit : true
                })).concat([{
                    id: -1, 
                    classifyName: 'User has not defined category',
                    canEdit: false,
                    canDeleted: false,
                    code: ''
                }])
            }),
        },
        mounted() {
            this.refreshData();
        },
        methods: {
            refreshData() {
                this.$store.dispatch("common/getAllClassify");
            },
            editItem(item) {
                this.itemModel = Object.assign({}, item);
                this.isEditMode = true;
            },
            cancelEdit() {
                this.itemModel = { classifyName: '' };
                this.isEditMode = false;
            },
            closeModal() {
                this.itemModel = { classifyName: '' };
                this.isEditMode = false;
                this.itemDetail = null;
                this.isLoadingPackageInfo = false;
                this.$set(this, "listServicePackClassify", []);
            },
            saveChange() {
                if (this.isEditMode) {
                    this.isLoading = true;
                    userTagClassifyApi.modifyClassify(this.itemModel).then((res) => {
                        try {
                            if (res.data.result === 0) {
                                this.refreshData();
                                this.itemModel = { classifyName: '' };
                                this.isEditMode = false;
                                this.$emit('collectionChange');
                            }
                            this.isLoading = false;
                        } catch (error) {
                            console.log("modifyClassify -> error", error);
                            this.isLoading = false;
                        }
                    }) 
                    .catch(error => {
                        this.processCatchApiRequest(error, "Request failed ...", 5);
                        this.isLoading = false;
                    });
                }
                else {
                    this.isLoading = true;
                    userTagClassifyApi.createClassify(this.itemModel).then((res) => {
                        try {
                            if (res.data.result === 0) {
                                this.refreshData();
                                this.itemModel = { classifyName: '' };
                                this.isEditMode = false;
                                this.$emit('collectionChange');
                            }
                            this.isLoading = false;
                        } catch (error) {
                            console.log("createClassify -> error", error);
                            this.isLoading = false;
                        }
                    })
                    .catch(error => {
                        this.processCatchApiRequest(error, "Request failed ...", 5);
                        this.isLoading = false;
                    });
                }
            },
            deleteItem(item) {
                this.$confirm('This will permanently delete the tag. All users tagged this will be cleaned. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.isLoading = true;
                    userTagClassifyApi.deleteClassify(item.id).then((res) => {
                        try {
                            if (res.data.result === 0) {                                
                                this.refreshData();
                                this.itemModel = { classifyName: '' };
                                this.isEditMode = false;
                                this.$emit('collectionChange');
                                this.$message({
                                    type: 'success',
                                    message: 'Delete completed'
                                });
                            }
                            this.isLoading = false;
                        } catch (error) {
                            console.log("getAllClassifyByStaff -> error", error);
                            this.isLoading = false;
                        }
                    })
                    .catch(error => {
                        this.processCatchApiRequest(error, "Request failed ...", 5);
                        this.isLoading = false;
                    });
                }).catch(() => {
                    
                });
            },
            packageInfo() {
                if (this.itemDetail != null) {
                    this.isLoadingPackageInfo = true;
                    userTagClassifyApi.packageInfo(this.itemDetail.id).then((res) => {
                        try {
                            if (res.data.result === 0) {
                                this.$set(this, "listServicePackClassify", res.data.data.map(x => ({ ...x, isEnabledBool : x.IsEnabled === 1 })));
                            }
                        } catch (error) {
                            console.log("packageInfo -> error", error);
                        }
                        this.isLoadingPackageInfo = false;
                    })
                    .catch(error => {
                        this.processCatchApiRequest(error, "Request failed ...", 5);
                        this.isLoadingPackageInfo = true;
                    });
                }
                else {
                    this.$set(this, "listServicePackClassify", []);
                    this.isLoadingPackageInfo = false;
                }
            },
            onPakageEnabledChanged(item) {
                this.isLoadingPackageInfo = true;
                userTagClassifyApi.enabledPackage({
                    userClassifyId: this.itemDetail.id,
                    packageId: item.PackageId,
                    isEnabled: !item.isEnabledBool
                }).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.packageInfo();
                            this.$message({
                                type: 'success',
                                dangerouslyUseHTMLString: true,
                                message: `Package <strong class='text-primary'>${item.PackageName} --> ${!item.isEnabledBool ? 'disabled': 'enabled'}</strong> on <strong class='text-primary'>${this.itemDetail.classifyName}</strong> group completed`
                            });                
                        }
                    } catch (error) {
                        console.log("onPakageEnabledChanged -> error", error);
                    }
                    this.isLoadingPackageInfo = false;
                })
                .catch(error => {
                    this.processCatchApiRequest(error, "Request failed ...", 5);
                    this.isLoadingPackageInfo = false;
                });
            }
        }
    }
</script>